/**
 * Welcome to gatsby-plugin-mdx!
 *
 * Start reading in gatsby-node.js
 */

const MDXRenderer = require("./mdx-renderer");

module.exports = {
  MDXRenderer: MDXRenderer
};
